<template>
  <b-card class="card-custom card-stretch card-rounded" no-body>
    <b-card-header
      class="d-flex justify-content-end align-content-center border-0"
    >
      <b-button
        class="font-weight-bolder mr-2"
        :variant="!table ? 'primary' : 'outline-secondary'"
        pill
        @click="table = !table"
      >
        {{ $t("dashboard.cybersecurity.achievements") }}
      </b-button>
      <b-button
        class="font-weight-bolder"
        :variant="table ? 'primary' : 'outline-secondary'"
        pill
        @click="table = !table"
      >
        {{ $t("dashboard.cybersecurity.punctuation") }}
      </b-button>
    </b-card-header>
    <b-card-body v-if="isAchievementsLoaded">
      <b-row class="mt-10">
        <b-col xl="3" md="6">
          <achievements-summary
            :achievements="achievements"
          ></achievements-summary>
        </b-col>
        <b-col xl="9" md="6" v-if="!table">
          <achievements-list :achievements="achievements"></achievements-list>
        </b-col>
        <b-col xl="9" md="6" v-if="table">
          <punctuation-list :punctuations="punctuations"></punctuation-list>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AchievementsList from "@/components/users/AchievementsList.component";
import PunctuationList from "@/components/users/PunctuationList.component";
import AchievementsSummary from "@/components/users/AchievementsSummary.component";

export default {
  name: "Achievements",
  components: {
    AchievementsList,
    PunctuationList,
    AchievementsSummary
  },
  data() {
    return {
      table: false
    };
  },
  methods: {
    ...mapActions("Profile", ["loadAchievements"])
  },
  computed: {
    ...mapGetters("Constants", ["isConstantsActive"]),
    ...mapGetters("Profile", [
      "achievements",
      "punctuations",
      "isAchievementsLoaded"
    ])
  },
  mounted() {
    if (this.isConstantsActive && !this.isAchievementsLoaded) {
      this.loadAchievements();
    }
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) {
        this.loadAchievements();
      }
    },
    isAchievementsLoaded(value) {
      if (this.isConstantsActive && value === false) {
        this.loadAchievements();
      }
    }
  }
};
</script>

<style>
.logo-container {
  margin: 0 1rem;
  padding: 1rem 0;
  height: 3.5rem;
  width: 3.5rem;
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;
}
</style>
