<template>
  <div>
    <b-table
      v-if="items.length"
      id="achievements-table"
      hover
      :items="items"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
      responsive="sm"
      stacked="sm"
      show-empty
    >
      <template #cell(logo)="row">
        <div :style="'opacity: ' + calculateOpacity(row.item.achieved)">
          <div
            v-html="row.item.logo"
            :class="'logo-container-' + row.item.achievementId"
          ></div>
        </div>
      </template>
      <template #cell(name)="row">
        <span :class="achievementCompleted(row.item.achieved)">
          {{ row.item.name }}
        </span>
      </template>
      <template #cell(description)="row">
        <span :class="achievementCompleted(row.item.achieved)">
          {{ row.item.description }}
        </span>
      </template>
      <template #cell(punctuation)="row">
        <span :class="achievementCompleted(row.item.achieved)">
          {{ row.item.punctuation }}
        </span>
      </template>
      <template #cell(achieved)="row">
        <div class="text-center">
          <div v-if="row.item.achieved && row.item.claim == 0">
            <span v-if="row.item.maxLevel > 1"
              >{{ $t("tables.level") }} {{ row.item.level }}</span
            >
            <b-icon-check v-else variant="success" scale="2"></b-icon-check>
          </div>
          <b-button
            v-if="row.item.achieved && row.item.claim == 1"
            variant="primary"
            class="font-weight-bolder font-size-sm p-2 rounded"
            @click="claimAchieve(row.item)"
          >
            {{ $t("tables.claim") }}
          </b-button>
        </div>
      </template>
      <template #cell(date)="row">
        <span :class="achievementCompleted(row.item.achieved)">
          {{ row.item.date }}
        </span>
      </template>
    </b-table>
    <div class="flex-wrap mx-auto" v-if="total > 0">
      <b-pagination
        class="d-flex flex-wrap mt-3 py-2 mr-3"
        v-model="currentPage"
        :total-rows="total"
        :per-page="perPage"
        aria-controls="users-table"
        first-number
        pill
        last-number
        align="center"
        size="lg"
      >
      </b-pagination>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import { BIconCheck } from "bootstrap-vue";
import { claimAchievement } from "@/api/user/achievements.api";
import { mapActions } from "vuex";

export default {
  name: "AchievementsList",
  components: {
    BIconCheck
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1
    };
  },
  props: {
    achievements: Array
  },
  computed: {
    total() {
      return _.size(this.achievements);
    },
    items() {
      let result = [];

      _.forEach(this.achievements, element => {
        let id = localStorage.getItem("stakeholderId");
        result.push({
          id: element.achievementId,
          logo: element.achievement.logo,
          name: element.achievement.name,
          description: element.achievement.description,
          punctuation: element.pointsWallet,
          achieved: !_.isEmpty(element.stakeholders),
          date: !_.isEmpty(element.stakeholders)
            ? moment(element.stakeholders[id].achievementDate).format(
                "DD/MM/YYYY"
              )
            : null,
          claim: !_.isEmpty(element.stakeholders)
            ? element.stakeholders[id].tradeMissing
            : null,
          level: !_.isEmpty(element.stakeholders)
            ? element.stakeholders[id].level
            : null,
          maxLevel: element.maxLevel
        });
      });

      return result;
    },
    fields() {
      let fields = [
        {
          key: "logo",
          label: "",
          sortable: false,
          class: "align-middle"
        },
        {
          key: "name",
          label: this.$tc("tables.achievement", 1),
          sortable: false,
          class: "align-middle"
        },
        {
          key: "description",
          label: this.$tc("tables.description", 1),
          sortable: false,
          class: "align-middle"
        },
        {
          key: "punctuation",
          label: this.$tc("tables.punctuation_per_level", 1),
          sortable: false,
          class: "align-middle"
        },
        {
          key: "achieved",
          label: this.$tc("tables.achieved", 1),
          sortable: false,
          class: "align-middle"
        },
        {
          key: "date",
          label: this.$tc("tables.date", 1),
          sortable: false,
          class: "align-middle"
        }
      ];

      return fields;
    }
  },
  methods: {
    ...mapActions("Profile", ["loadAchievements"]),
    achievementCompleted(achieved) {
      return !achieved ? "text-muted" : "text-body";
    },
    calculateOpacity(achieved) {
      return !achieved ? 0.5 : 1;
    },
    claimAchieve(item) {
      claimAchievement(item.id)
        .then(() => {
          this.$root.$bvToast.toast(
            this.$t("user.toast.success.punctuation", {
              punctuation: item.punctuation
            }),
            {
              title: this.$t("user.toast.success.achieve"),
              variant: "success",
              solid: true,
              toaster: "b-toaster-top-center"
            }
          );
          this.loadAchievements();
        })
        .catch(() => {
          this.$root.$bvToast.toast(this.$t("user.toast.error.achieve"), {
            title: this.$t("user.toast.error.title"),
            variant: "error",
            solid: true,
            toaster: "b-toaster-top-center"
          });
        });
    }
  }
};
</script>
