<template>
  <div>
    <div class="d-flex flex-column align-self-center">
      <avatar :showChange="false" :avatar="avatar" />
    </div>
    <div
      class="text-center font-weight-bolder text-dark-75 font-size-h5 mb-0 mt-6 p-4"
    >
      <div>{{ fullname }}</div>
      <div>{{ $t("tables.total_achieved") }} {{ totalAchievements }}</div>
      <div>
        {{ $t("tables.punctuation_achieved") }} {{ wallet || 0 }} pks
        <i
          class="ml-2 fa fa-info-circle text-info"
          v-b-tooltip.hover
          :title="$t('user.tooltips.info_achievements')"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import Avatar from "@/components/profile/Avatar.component";

export default {
  name: "AchievementsSummary",
  components: {
    Avatar
  },
  props: {
    achievements: Array
  },
  computed: {
    ...mapGetters("User", ["avatar", "fullname"]),
    ...mapGetters("Profile", ["wallet"]),
    totalAchievements() {
      let id = localStorage.getItem("stakeholderId");
      let result = _.filter(this.achievements, achievement => {
        return achievement.stakeholders[id] !== undefined;
      }).length;
      return result;
    },
    totalPunctuation() {
      let id = localStorage.getItem("stakeholderId");
      let punctuation = 0;
      let achieved = _.filter(this.achievements, achievement => {
        return (
          achievement.stakeholders[id] != undefined &&
          achievement.stakeholders[id].tradeMissing == 0
        );
      });
      _.forEach(
        achieved,
        element => (punctuation += Number(element.pointsWallet))
      );

      return punctuation;
    }
  }
};
</script>
