<template>
  <div v-if="items.length > 0">
    <b-table
      id="punctuation-table"
      hover
      :items="items"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
      responsive="sm"
      stacked="sm"
      show-empty
    >
    </b-table>
    <div class="flex-wrap mx-auto" v-if="total > 0">
      <b-pagination
        class="d-flex flex-wrap mt-3 py-2 mr-3"
        v-model="currentPage"
        :total-rows="total"
        :per-page="perPage"
        aria-controls="users-table"
        first-number
        pill
        last-number
        align="center"
        size="lg"
      >
      </b-pagination>
    </div>
  </div>
  <div v-else>
    {{ $tc("dashboard.alerts.interaction.user.empty") }}
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment-timezone";

export default {
  name: "PunctuationList",
  data() {
    return {
      perPage: 10,
      currentPage: 1
    };
  },
  props: {
    punctuations: Array
  },
  computed: {
    total() {
      return _.size(this.punctuations);
    },
    fields() {
      let fields = [
        {
          key: "date",
          label: this.$tc("tables.date", 1),
          sortable: false,
          class: "align-middle"
        },
        {
          key: "name",
          label: this.$tc("tables.achieved", 1),
          sortable: false,
          class: "align-middle"
        },
        {
          key: "level",
          label: this.$tc("tables.level", 1),
          sortable: false,
          class: "align-middle"
        },
        {
          key: "punctuation",
          label: this.$tc("tables.punctuation", 1),
          sortable: false,
          class: "align-middle"
        }
      ];

      return fields;
    },
    items() {
      let result = [];

      _.forEach(this.punctuations, element => {
        result.push({
          date: moment(element.achievementDate).format("DD/MM/YYYY"),
          name: element.achievement.name,
          level: element.level,
          punctuation: element.pointsWallet
        });
      });

      return _.orderBy(result, ["date"], ["asc", "desc"]);
    }
  }
};
</script>
